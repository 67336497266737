<template>
  <div id="preview">
    <v-dialog v-model="memberDocumentPrompt" persistent scrollable>
      <upload-selected-member-document
        :record="member"
        v-if="memberDocumentPrompt"
        :showDialog="memberDocumentPrompt"
        @hideDialog="hideMemberDocumentPrompt($event)"
      ></upload-selected-member-document>
    </v-dialog>
    <v-dialog v-model="memberPhotoPrompt" persistent scrollable>
      <upload-selected-member-photo
        :record="member"
        v-if="memberPhotoPrompt"
        :showDialog="memberPhotoPrompt"
        @hideDialog="hideMemberPhotoPrompt($event)"
      ></upload-selected-member-photo>
    </v-dialog>
    <v-overlay :value="SubmitFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageDescription }} - {{ YearName }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-form ref="form1" v-model="valid1" lazy-validation>
        <v-card-text>
          <p><span class="text-danger">*</span> indicates required field</p>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-container>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  elevation="30"
                  shaped
                  tile
                  small
                  class="font-size-h6 mr-3 white--text"
                  @click.prevent="refreshPageData"
                  color="#a4c639"
                  >Refresh</v-btn
                >
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Designation</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="DesignationIdOptionsLoading"
                  :items="DesignationIdOptions"
                  :rules="DesignationIdRules"
                  v-model="DesignationId"
                  required
                  outlined
                  dense
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="4">
                <h6><span class="text-danger">*</span> Membership Id</h6>
                <v-text-field
                  v-model="MembershipId"
                  :rules="MembershipIdRules"
                  placeholder="Membership Id"
                  required
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col align="center" cols="12" sm="6" lg="3" md="3">
                <v-btn
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  elevation="30"
                  class="mr-4 mt-4 white--text"
                  >Search</v-btn
                >
              </v-col>
              <!-- <v-col cols="12" md="6">
                <label>
                  <h6><span class="text-danger">*</span> Member Zone</h6>
                </label>
                <v-autocomplete
                  @change="getMemberIdOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col> -->
            </v-row>
            <!-- <v-row>
              <v-col cols="12" md="6">
                <label>
                  <h6><span class="text-danger">*</span> Select Member</h6>
                </label>
                <v-autocomplete
                  @change="getMemberDetails"
                  :reduce="(option) => option.value"
                  :loading="MemberIdOptionsLoading"
                  :items="MemberIdOptions"
                  :rules="MemberIdRules"
                  v-model="MemberId"
                  required
                  outlined
                  dense
                  clearable
                >
                  <template v-slot:selection="{ item }">
                    <img width="50" height="50" :src="item.photo" />
                    {{ item.text }}
                  </template>
                  <template v-slot:item="{ item }">
                    <img width="50" height="50" :src="item.photo" />
                    {{ item.text }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row> -->
            <v-row v-if="MemberId > 0">
              <v-col cols="12" sm="12" md="12" class="text-center">
                <img
                  width="100"
                  height="auto"
                  :src="MemberDetails.MemberImage"
                />
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Name</label>
                <h5>{{ MemberDetails.Fullname }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Membership Id</label>
                <h5>{{ MemberDetails.MembershipId }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Zone</label>
                <h5>{{ MemberDetails.SmaZoneName }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Lom</label>
                <h5>{{ MemberDetails.LomName }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Designation</label>
                <h5>{{ MemberDetails.MemberDesignation }}</h5>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <label>Member Contact Number</label>
                <h5>{{ MemberDetails.MobileNo }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Email</label>
                <h5>{{ MemberDetails.EmailId }}</h5>
              </v-col>

              <v-col cols="12" sm="6" md="3">
                <label>Member Address</label>
                <h5>{{ MemberDetails.AddressTxt }}</h5>
              </v-col>
            </v-row>

            <v-row wrap v-if="MemberId > 0">
              <v-col cols="12" sm="6" md="3">
                <h4>Date of Birth</h4>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      required
                      v-model="MemberDetails.DOB"
                      label=" Date of Birth"
                      hint="Select a Date of Birth"
                      persistent-hint
                      readonly
                      v-on="on"
                      background-color="#F3F6F9"
                      class="form-control form-control-lg form-control-solid"
                      validate-on-blur
                      solo
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="MemberDetails.DOB"
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Profile Update</h4>
                <v-chip
                  :color="`${
                    MemberDetails.ProfileUpdate == 1 ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{
                    MemberDetails.ProfileUpdate == 1 ? "Completed" : "Pending"
                  }}
                </v-chip>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Photo Update</h4>
                <v-chip
                  :color="`${
                    MemberDetails.PhotoUpdate ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ MemberDetails.PhotoUpdate ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberPhotoPrompt = true"
                  v-if="MemberDetails.PhotoUpdate == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <h4>Age Proof Document Upload</h4>
                <v-chip
                  :color="`${
                    MemberDetails.DocumentUpdate ? '#0f9d58' : '#f4b400'
                  }`"
                  draggable
                  dark
                  >{{ MemberDetails.DocumentUpdate ? "Completed" : "Pending" }}
                </v-chip>
                &nbsp;
                <v-icon
                  icon
                  size="25px"
                  color="primary"
                  @click.prevent="memberDocumentPrompt = true"
                  v-if="MemberDetails.DocumentUpdate == false"
                >
                  mdi-cloud-upload
                </v-icon>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="closePrompt">Close</v-btn>
          <v-btn
            color="blue darken-1"
            text
            :loading="SubmitFlag"
            :disabled="!valid1"
            @click="confirmAlert"
            >Save</v-btn
          >-->
          <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-5 mr-2 white--text"
            >Close</v-btn
          >
          <v-btn
            :disabled="!valid1"
            @click.prevent="confirmAlert"
            :loading="SubmitFlag"
            elevation="30"
            shaped
            tile
            small
            color="#1db954"
            class="font-size-h6 px-5 mr-2 white--text"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import UploadSelectedMemberDocument from "@/view/pages/members/UploadSelectedMemberDocument.vue";
import UploadSelectedMemberPhoto from "@/view/pages/members/UploadSelectedMemberPhoto.vue";

export default {
  mixins: [common],
  components: {
    UploadSelectedMemberDocument,
    UploadSelectedMemberPhoto,
  },
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      memberDocumentPrompt: false,
      memberPhotoPrompt: false,
      ResultFlag: false,

      member: {},
      menu1: false,

      MemberDetails: {},

      ZoneCodeRules: [(v) => !!v || "Region is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "MembershipId is required"],

      MemberIdRules: [(v) => !!v || "Member is required"],
      MemberId: "",
      MemberIdOptions: [],
      MemberIdOptionsLoading: false,

      DesignationIdRules: [(v) => !!v || "Designation is required"],
      DesignationId: "",
      DesignationIdOptions: [],
      DesignationIdOptionsLoading: false,

      AllMembers: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.pageData();
      }
    },
    MemberIdOptions: function () {
      this.MemberIdOptionsLoading = false;
      this.getMemberDetails();
    },
    ZoneCodeOptions: function () {
      this.ZoneCodeOptionsLoading = false;
    },
    DesignationIdOptions: function () {
      this.DesignationIdOptionsLoading = false;
    },
    MembershipId: function () {
      this.MemberDetails = {};
      this.MemberId = "";
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "sma_national_governing_board",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    hideMemberDocumentPrompt(flag) {
      console.log("hideMemberDocumentPrompt called");
      console.log({ flag });
      this.memberDocumentPrompt = false;
      if (flag) {
        this.MemberDetails.DocumentUpdate = true;
      }
    },
    hideMemberPhotoPrompt(flag) {
      console.log("hideMemberPhotoPrompt called");
      console.log({ flag });
      this.memberPhotoPrompt = false;
      if (flag) {
        this.MemberDetails.PhotoUpdate = true;
      }
    },
    pageData() {
      console.log("pageData called");
      this.getDesignationIdOptions();
      // this.getZoneCodeOptions();
    },
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt",this.ResultFlag);
    },
    getMemberIdOptions() {
      console.log("getMemberIdOptions called");
      // this.MemberIdOptions = [];
      this.MemberIdOptionsLoading = true;
      var selectbox1_source = "MemberId";
      var selectbox1_destination = "MemberIdOptions";
      var selectbox1_url = "api/members/options";
      var selectbox1_conditions_array = {
        UserInterface: 5,
        MemberId: this.MemberId,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMemberDetails() {
      console.log("getMemberDetails called");
      this.AllMembers = [];
      var temp_code = this.MemberId;
      console.log("temp_code=" + temp_code);
      var idx = this.MemberIdOptions.map((e) => e.value).indexOf(temp_code);
      console.log("idx=" + idx);
      if (idx >= 0) {
        var member = this.MemberIdOptions[idx];
        console.log("member=" + JSON.stringify(member));
        this.member = member;
        this.AllMembers.push({
          YearwiseOfficesHeldId: null,
          MemberId: member.value,
          MemberName: member.member_name,
          DesignationId: this.DesignationId,
          Designation: "",
        });
      }
    },
    getDesignationIdOptions() {
      console.log("getDesignationIdOptions called");
      this.DesignationIdOptionsLoading = true;
      var selectbox1_source = "DesignationId";
      var selectbox1_destination = "DesignationIdOptions";
      var selectbox1_url = "api/designation/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        OrganizationTypeId: 4,
        ExceptDesignationId: [1, 4, 5, 6, 7],
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      this.MemberId = "";
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/zone/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },

    searchForm() {
      console.log("searchForm is called");
      var MembershipId = this.MembershipId;
      console.log("MembershipId=" + MembershipId);
      if (MembershipId != "") {
        this.SearchFlag = true;
        this.Member = {};
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: this.MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );
        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;
        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            // thisIns.pageLoadingOff()
            thisIns.SearchFlag = false;
            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.MemberDetails = records;
              thisIns.MemberId = records.MemberId;
              thisIns.getMemberIdOptions();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Membership Id is required";
        this.sweetAlert("error", message, false);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      // add todo in store and clear all fields in dialog
      console.log("submitForm called");

      // this.resetMessageTxt();

      var result = this.$refs.form1.validate();

      var temp_member = this.MemberDetails;
      console.log("temp_member =" + JSON.stringify(temp_member));

      var SmaZoneId = temp_member.SmaZoneId;
      console.log({ SmaZoneId });

      var ZoneCode = SmaZoneId;
      var MemberId = this.MemberId;
      var DesignationId = this.DesignationId;

      console.log(
        "MemberId=" +
          MemberId +
          ", ZoneCode=" +
          ZoneCode +
          ", DesignationId=" +
          DesignationId
      );

      if (result && ZoneCode != "" && MemberId != "" && DesignationId != "") {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/year-wise-designation/bulk-update";
        var upload = {
          UserInterface: 4,
          YearCode: this.JciYearCode,
          ZoneCode: ZoneCode,
          MemberId: MemberId,
          DesignationId: DesignationId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.LoadingFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        console.log("some errors occured");
        var message = "";
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        // if (n1 == "") {
        //   message += "Atleast one member is required. ";
        // }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
